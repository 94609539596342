import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container } from '../components/UI';
import { EMOTION_BREAKPOINTS } from '../constants/breakpoints';
import theme from '../theme';

const Iframe = styled.iframe`
  width: 100%;
  flex-grow: 1;
  min-height: 1000px;
  border: none;

  ${EMOTION_BREAKPOINTS.sm} {
    width: initial;
  }
`;

const Contact = () => {
  return (
    <Layout>
      <Seo title="contact" />
      <Container as="section">
        <div
          css={css`
            display: flex;
            align-items: center;
            flex-direction: column-reverse;
            padding-top: ${theme.spacing[4]};

            ${EMOTION_BREAKPOINTS.sm} {
              flex-direction: row;
              justify-content: space-between;
              .pageContainer-nfp_a,
              .wink .pageContainer-nfp_a {
                padding: 48px;
              }
            }
            .pageContainer-nfp_a,
            .wink .pageContainer-nfp_a {
              padding: 0px;
            }
          `}
        >
          <Iframe
            src="https://us18.list-manage.com/contact-form?u=bbc3339e77698e9bc201c7b7c&form_id=8731f5b9b5f8b27cc595cc838d2a3a0c"
          />
          <div
            css={css`
              order: 1;
              max-width: 239px;
              margin-bottom: ${theme.spacing[5]};
              ${EMOTION_BREAKPOINTS.sm} {
                max-width: 550px;
                margin-bottom: 0;
                margin-left: ${theme.spacing[5]};
              }
            `}
          >
            <StaticImage
              src="../images/contact.svg"
              alt="contact"
              width="550"
              quality={100}
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};
export default Contact;
